import styled from 'styled-components'
import {  Flex, Heading, Text, Link, useMatchBreakpoints, Button, TwitterIcon, TelegramIcon } from '@pancakeswap/uikit'
import { useTranslation } from 'contexts/Localization'
import ConnectWalletButton from 'components/ConnectWalletButton'
import Container from 'components/Layout/Container'
import { useWeb3React } from '@web3-react/core'
import SunburstSvg from './SunburstSvg'

const BgWrapper = styled.div`
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
`

const StyledSunburst = styled(SunburstSvg)`
  height: 350%;
  width: 350%;

  ${({ theme }) => theme.mediaQueries.xl} {
    height: 400%;
    width: 400%;
  }
`

const Wrapper = styled(Flex)`
  z-index: 1;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`

const FloatingPancakesWrapper = styled(Container)`
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  visibility: hidden;

  ${({ theme }) => theme.mediaQueries.md} {
    visibility: visible;
  }
`

const TopLeftImgWrapper = styled(Flex)`
  position: absolute;
  left: 0;
  top: 0;
`


const Footer = () => {
  const { t } = useTranslation()

  return (
    <>
      <BgWrapper>
        <Flex alignItems="center" justifyContent="center" width="100%" height="100%">
          <StyledSunburst />
        </Flex>
      </BgWrapper>
      <Wrapper>
        <Heading mb="24px" scale="xl" color="white">
          {t('Join us on social media')}
        </Heading>
        <Text textAlign="center" color="white" mb="24px">
          {t("Don't miss out on the latest news and updates")}
        </Text>

        <Flex mb="24px" style={{ gap: '12px' }}>
          <Button
            as="a"
            title={'Cronos Cats Club Twitter'}
            href="https://twitter.com/CronosCats"
            variant="primary"
            scale="md"
            mr="8px"
          >
            <TwitterIcon color="currentColor" mr="8px" /> {'Twitter'}
          </Button>
          <Button
            as="a"
            title={'Cronos Cats Club Telegram'}
            href="https://t.me/cronoscats"
            variant="primary"
            scale="md"
            mr="8px"
          >
            <TelegramIcon color="currentColor" mr="8px" /> {'Telegram'}
          </Button>
        </Flex>
      </Wrapper>
    </>
  )
}

export default Footer
