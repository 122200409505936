import { TranslateFunction } from 'contexts/Localization/types'
import { SalesSectionProps } from '.'

export const swapSectionData = (t: TranslateFunction): SalesSectionProps => ({
  headingText: ('Exclusive Airdrops for NFT Holders'),
  bodyText: ('All NFT holders, even those with free NFTs, will receive new tokens, including $CCC.'),
  reverse: false,
  primaryButton: {
    to: '/claim',
    text: ('Claim Tokens'),
    external: false,
  },
  secondaryButton: {
    to: 'https://docs.cronoscats.club',
    text: ('Learn'),
    external: true,
  },
  images: {
    path: '/images/home/trade/',
    attributes: [
      { src: 'usdt', alt: ('USDT token') },
      { src: 'bitcoin', alt: ('BTC token') },
      { src: 'cronos2', alt: ('CRO token') },
    ],
  },
})

export const earnSectionData = (t: TranslateFunction): SalesSectionProps => ({
  headingText: ('Cronos Cats Membership Pass'),
  bodyText: t(
    'Cronos Cats Membership Pass provides you with exclusive access to the entire project ecosystem. Explore the innovative realm of NFT Membership, where ownership meets seamless utility!',
  ),
  reverse: true,
  primaryButton: {
    to: '/nfts/collections/mint/0x9b389aeBC343C6B759E23334733F32D779595ea5',
    text: 'Mint',
    external: false,
  },
  secondaryButton: {
    to: '/nft-staking',
    text: 'Stake',
    external: true,
  },
  images: {
    path: '/images/cronoscats/nfts/',
    attributes: [
      { src: '1', alt: '1' },
      { src: '4', alt: '4' },
      { src: '2', alt: '2' },
      { src: '3', alt: '3' },

    ],
  },
})


export const cakeSectionData = (t: TranslateFunction): SalesSectionProps => ({
  headingText: ('CAKE makes our world go round.'),
  bodyText: ('CAKE token is at the heart of the PancakeSwap ecosystem. Buy it, win it, farm it, spend it, stake it... heck, you can even vote with it!'),
  reverse: false,
  primaryButton: {
    to: '/swap?outputCurrency=0xb9A06cC294dA2B263aaf9E3E24194968b3f867eE',
    text: ('Buy CCC'),
    external: false,
  },
  secondaryButton: {
    to: 'https://docs.cronoscats.club',
    text: ('Learn'),
    external: true,
  },

  images: {
    path: '/images/home/cake/',
    attributes: [
      { src: 'bottom-right', alt: t('CCC angry birds') },
      { src: 'top-right', alt: t('CCC Simpsons') },
      { src: 'coin', alt: t('CCC Vader') },
      { src: 'top-left', alt: t('CCC minions') },
    ],
  },
})
