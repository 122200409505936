/** @jsxImportSource theme-ui */
import React from 'react'
import Link from 'next/link'
import { styles } from './styles'
import { Button, Flex, Text } from '@pancakeswap/uikit'
import { Box, Image } from 'theme-ui'

const CronosCats = () => {

  return (
    <Flex sx={styles.slideContainer}>
      <Flex sx={styles.slideContent}>
        <Text sx={{ ...styles.slideTitle, fontSize: ['38px', '34px', '58px']}}>
          {'Welcome to Cronos Cats Club'}
        </Text>
        <Text sx={styles.slideSubtitle}>
          {'Yield Farming, NFT Staking, NFT Drops, IDO and exclusive Airdrops!'}
        </Text>
        <Flex sx={{ alignItems: 'center', marginTop: ['25px', '25px', '0px'] }}>
          <Text sx={styles.availableOn}>{'AVAILABLE ON'}</Text>
          <Link href="https://minted.network/collections/cronos/0xa2ebd9d8a5829e64598fb6159d5a187c73a4029a" target="_blank">
          <img 
          src="/images/banners/logo/minted.png" alt="minted.network" width={20} height={20} 
          sx={{ marginRight: '10px', cursor: 'pointer' }}
          />
          </Link>
       {/*  <Link href="https://crypto.com/nft/collection/" target="_blank">
          <img 
          src="/images/banners/logo/cronos.png" alt="crypto.com" width={20} height={20} 
          sx={{ marginRight: '10px', cursor: 'pointer' }}
          />
          </Link>
          <Link href="https://app.ebisusbay.com/collection/" target="_blank">
          <img 
          src="/images/banners/logo/ebisus.jpg" alt="ebisus" width={20} height={20} 
          sx={{ marginRight: '10px', cursor: 'pointer' }}
          />
  </Link> */}
        </Flex>
        <Link href="https://cronoscats.club">
          <Flex sx={styles.billImage}>
            <Box sx={{ ...styles.image, backgroundImage: `url('/images/banners/top/1.png')` }} />
          </Flex>
        </Link>
        <Flex sx={styles.buttonContainer}>
          <Button
   
            sx={{ ...styles.learnMoreButton }}
            onClick={() =>
              window.open(
                '/nfts/collections/',
              )
            }
          >
            {'Mint NFTs'}
          </Button>
          <Button
            sx={{ fontSize: ['14px', '14px', '16px'], minWidth: ['140px', '140px'] }}
            onClick={() => window.open('/nft-staking')}
          >
            {'Stake NFT'}
          </Button>
        </Flex>
      </Flex>
      <Flex sx={{ width: ['0', '100%'], justifyContent: 'center' }}>
        <Flex
          sx={{ ...styles.imageWrapper, background: 'none' }}
          onClick={() => window.open('https://ido.cronoscats.club')}
        >
          <Image src={`/images/banners/top/2.png`} sx={styles.imageCCC} />
        </Flex>
      </Flex>
    </Flex>
  )
}

export default CronosCats
